"use strict";

var _interopRequireDefault = require("D:/ad/ad-be-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.config = void 0;
exports.getRequest = getRequest;
exports.loginAction = loginAction;
exports.logoutAction = logoutAction;
exports.postRequest = postRequest;
exports.postRequestWithoutLoading = postRequestWithoutLoading;
var _vue = _interopRequireDefault(require("vue"));
var _vueResource = _interopRequireDefault(require("vue-resource"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _settings = _interopRequireDefault(require("@/settings"));
var _clipboard = _interopRequireDefault(require("@/directive/clipboard/clipboard"));
var _main = _interopRequireDefault(require("../main.js"));
var _elementUi = require("element-ui");
// import router from "@/router";
// import Router from "@/router";

_vue.default.use(_vueResource.default);
var TokenKey = 'token';

// create an axios instance
var config = exports.config = {
  url: _settings.default.url + '/',
  // url = base url + request url
  // url: process.env.VUE_APP_BASE_API, // url = base url + request url
  timeout: 5000 // request timeout
};
var fail_data = {
  code: 0,
  reason: '',
  data: {}
};
function deal_result(result) {
  if (result.status == '200') {
    return result;
  } else if (result.status == '5000') {
    // 系统异常
    _vue.default.prototype.$message({
      'type': 'error',
      'message': '系统异常 \n ' + '参数或者路径错误,请检查请求!'
    });
  } else if (result.status == '401' || result.status == '5001') {
    // 登录过期或未登录
    _vue.default.prototype.$message({
      'type': 'error',
      'message': '登录过期'
    });
  } else {
    return result;
  }
}
function postRequest(url, r_data, finished) {
  var loading = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
  var token = _jsCookie.default.get(TokenKey);
  _vue.default.http.headers.common['token'] = token;
  var loadingInstance = loading && _elementUi.Loading.service({
    fullscreen: true
  });
  _vue.default.http.post(config.url + url, r_data, {
    params: {}
  }).then(function (res) {
    loadingInstance && loadingInstance.close();
    deal_result(res);
    if (res.data.code == '200') {
      if (finished) {
        finished(res);
      }
    } else if (res.data.code == '401') {
      console.log(res.data.code, '， LOG OUT!');
      _main.default.$store.dispatch('user/logout').then(function (r) {
        return finished(res);
      });
      // await this.$store.dispatch('user/logout')
      // this.$router.push(`/login?redirect=${this.$route.fullPath}`)
      // hotties.$router.push(`/login?redirect='console'`)
      // Vue.prototype.$store.dispatch('user/logout')
      // Vue.prototype.$router.push(`/login?redirect=${this.$route.fullPath}`)
    } else {
      if (finished) {
        var failed = Object.assign({}, res); // copy obj
        finished(failed);
      }
    }
  }).catch(function (res) {
    loadingInstance && loadingInstance.close();
    if (res.data && res.data.code == '401') {
      console.log('401， LOG OUT!');
      _main.default.$store.dispatch('user/logout');
      _main.default.$router.push("/login?redirect='console'");
    }
  });
}
function postRequestWithoutLoading(url, r_data, finished) {
  var token = _jsCookie.default.get(TokenKey);
  _vue.default.http.headers.common['token'] = token;
  _vue.default.http.post(config.url + url, r_data, {
    params: {}
  }).then(function (res) {
    deal_result(res);
    if (res.data.code == '200') {
      if (finished) {
        finished(res);
      }
    } else if (res.data.code == '401') {
      console.log(res.data.code, '， LOG OUT!');
      _main.default.$store.dispatch('user/logout').then(function (r) {
        return finished(res);
      });
    } else {
      if (finished) {
        var failed = Object.assign({}, res); // copy obj
        finished(failed);
      }
    }
  }).catch(function (res) {
    if (res.data && res.data.code == '401') {
      console.log('401， LOG OUT!');
      _main.default.$store.dispatch('user/logout');
      _main.default.$router.push("/login?redirect='console'");
    }
  });
}
function getRequest(url, r_data, finished) {
  var token = _jsCookie.default.get(TokenKey);
  _vue.default.http.headers.common['token'] = token;
  _vue.default.http.get(config.url + url, r_data, {
    params: {}
  }).then(function (res) {
    deal_result(res);
    if (res.data.code == '200') {
      if (finished) {
        finished(res);
      }
    } else {
      if (finished) {
        var failed = Object.assign({}, res); // copy obj
        finished(res);
      }
    }
  }).catch(function (res) {
    if (res.data.code == '401') {
      console.log('401， LOG OUT!');
      _main.default.$store.dispatch('user/logout');
      _main.default.$router.push("/login?redirect='console'");
    }
  });
}
function loginAction(username, password, finished) {
  _vue.default.http.post(config.url + 'login', {
    'username': username,
    'password': password
  }, {
    params: {
      'timeout': config.timeout
    }
  }).then(function (res) {
    if (res.data.code == '200') {
      _jsCookie.default.set(TokenKey, res.data.data.token);
      if (finished) {
        finished(res);
      }
    } else {
      if (finished) {
        var failed = Object.assign({}, fail_data); // copy obj
        finished(failed);
      }
    }
  });
}
function logoutAction(username, password, finished) {
  var token = _jsCookie.default.get(TokenKey);
  _vue.default.http.post(config.url + 'api/logout', {
    'username': username,
    'password': password
  }, {
    params: {
      'token': token,
      'timeout': config.timeout
    }
  }).then(function (res) {
    if (res.data.code == '200') {
      if (finished) {
        finished(res);
      }
    } else {
      if (finished) {
        var failed = Object.assign({}, fail_data); // copy obj
        finished(failed);
      }
    }
  });
}