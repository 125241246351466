"use strict";

var _interopRequireDefault = require("D:/ad/ad-be-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getInfo = getInfo;
exports.login = login;
exports.logout = logout;
var _request = _interopRequireDefault(require("@/utils/request"));
function login(data) {
  return (0, _request.default)({
    url: '/login',
    // url: '/vue-element-admin/user/login',
    method: 'post',
    data: data
  });
}
function getInfo(token) {
  return (0, _request.default)({
    url: '/sysUser/getProfile',
    method: 'post',
    headers: {
      token: token
    }
  });
}
function logout(token) {
  return (0, _request.default)({
    url: '/logout',
    method: 'post',
    params: {
      token: token
    }
  });
}