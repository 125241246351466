import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);

/* Layout */
import Layout from '@/layout';

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will become nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
 roles: ['admin','editor']    control the routePath roles (you can set multiple roles)
 title: 'title'               the name show in sidebar and breadcrumb (recommend set)
 icon: 'svg-name'             the icon show in the sidebar
 noCache: true                if set true, the routePath will not be cached(default is false)
 affix: true                  if set true, the tag will affix in the tags-view
 breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
 activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
 }
 */

/**
 * constantRoutes
 * a base routePath that does not have permission requirements
 * all roles can be accessed
 */
export var constantRoutes = [{
  path: '/redirect',
  component: Layout,
  hidden: true,
  children: [{
    path: '/redirect/:path(.*)',
    component: function component() {
      return import('@/views/redirect/index');
    }
  }]
}, {
  path: '/login',
  component: function component() {
    return import('@/yy/login/index');
  },
  hidden: true
}, {
  path: '/auth-redirect',
  component: function component() {
    return import('@/yy/login/auth-redirect');
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return import('@/views/error-page/404');
  },
  hidden: true
}, {
  path: '/401',
  component: function component() {
    return import('@/views/error-page/401');
  },
  hidden: true
}, {
  path: '/',
  component: Layout,
  redirect: '/console',
  name: 'home',
  meta: {
    title: '数据看板',
    icon: 'dashboard'
  },
  children: [{
    path: 'console',
    component: function component() {
      return import('@/yy/console/summary/index.vue');
    },
    name: 'console',
    meta: {
      title: '总览',
      icon: 'dashboard',
      affix: true
    }
  }, {
    path: 'platformStats',
    component: function component() {
      return import('@/yy/console/platform/PlatformStats.vue');
    },
    name: 'platformStats',
    meta: {
      title: '数据总览',
      icon: 'table',
      affix: true
    }
  }, {
    path: '/adwakeuplog',
    component: function component() {
      return import('@/yy/pages/operation/adwakeuplog.vue');
    },
    name: 'wakeuplog/home',
    meta: {
      title: '用户统计-UV',
      name: 'wakeuplog',
      icon: 'eye-open'
    }
  }]
}, {
  path: '/logs',
  redirect: '/logs',
  component: Layout,
  name: 'logs',
  meta: {
    title: '日志',
    icon: 'size'
  },
  children: [{
    path: '/adlaunchlog',
    component: function component() {
      return import('@/yy/pages/operation/adlaunchlog.vue');
    },
    name: 'adlaunchlog/home',
    meta: {
      title: '用户启动日志',
      name: 'launchlog',
      icon: 'eye-open'
    }
  }, {
    path: '/adruntimelog',
    component: function component() {
      return import('@/yy/pages/operation/adruntimelog.vue');
    },
    name: 'blacklist/adruntimelog',
    meta: {
      title: '运行日志',
      name: 'runtimelog',
      icon: 'eye'
    }
  }, {
    path: '/admonitorlog',
    component: function component() {
      return import('@/yy/pages/operation/admonitorlog.vue');
    },
    name: 'blacklist/admonitorlog',
    meta: {
      title: '检测链接日志',
      name: 'jerry',
      icon: 'eye'
    }
  }, {
    path: '/adldylogA',
    component: function component() {
      return import('@/yy/pages/operation/adldylogA.vue');
    },
    name: 'blacklist/adldylogA',
    meta: {
      title: '母-落地页日志',
      name: 'aldylog',
      icon: 'eye-open'
    }
  }, {
    path: '/adldylog',
    component: function component() {
      return import('@/yy/pages/operation/adldylog.vue');
    },
    name: 'blacklist/adldylog',
    meta: {
      title: '子-落地页日志',
      name: 'ldylog',
      icon: 'eye'
    }
  }, {
    path: 'adStats',
    component: function component() {
      return import('@/yy/console/platform/AdStats.vue');
    },
    name: 'AdStats',
    meta: {
      title: '广告数据-设备',
      icon: 'table',
      affix: true
    }
  }, {
    path: 'adStatsAll',
    component: function component() {
      return import('@/yy/console/platform/AdStatsAll.vue');
    },
    name: 'AdStatsAll',
    meta: {
      title: '广告数据-全',
      icon: 'table',
      affix: true
    }
  }, {
    path: 'adUserMap',
    component: function component() {
      return import('@/yy/console/platform/AdUserMap.vue');
    },
    name: 'AdUserMap',
    meta: {
      title: '日志-用户画像',
      icon: 'table',
      affix: true
    }
  }]
}, {
  path: '/app',
  redirect: '/app',
  component: Layout,
  name: 'app',
  meta: {
    title: 'APP',
    icon: 'table'
  },
  children: [{
    path: '/app',
    component: function component() {
      return import('@/yy/pages/app/adapp.vue');
    },
    name: 'app/home',
    meta: {
      title: 'App',
      name: 'app',
      icon: 'star'
    }
  }, {
    path: '/aldyreview',
    component: function component() {
      return import('@/yy/pages/app/aldyreview.vue');
    },
    name: 'app/aldyreview',
    meta: {
      title: 'aldy审核状态',
      name: 'aldyreview',
      icon: 'star'
    }
  }, {
    path: '/appversion',
    component: function component() {
      return import('@/yy/pages/app/adappversion.vue');
    },
    name: 'app/appversion',
    meta: {
      title: '版本控制',
      name: 'appversion',
      icon: 'mobile'
    }
  }, {
    path: '/appconfig',
    component: function component() {
      return import('@/yy/pages/app/adconfig.vue');
    },
    name: 'app/appconfig',
    meta: {
      title: 'APP协议',
      name: 'config',
      icon: 'table'
    }
  }]
}, {
  path: '/route',
  redirect: '/route',
  component: Layout,
  name: 'route',
  meta: {
    title: '路由控制',
    icon: 'luyouqi'
  },
  children: [{
    path: '/route',
    component: function component() {
      return import('@/yy/pages/route/adroute.vue');
    },
    name: 'route/home',
    meta: {
      title: '路由',
      name: 'route',
      icon: 'list'
    }
  }, {
    path: '/routepath',
    component: function component() {
      return import('@/yy/pages/routePath/adroutepath.vue');
    },
    name: 'routepath/home',
    meta: {
      title: '页面路径',
      name: 'routepath',
      icon: 'tree-table'
    }
  }, {
    path: '/adpage',
    component: function component() {
      return import('@/yy/pages/window/adpage.vue');
    },
    name: 'page/home',
    meta: {
      title: '页面管理',
      name: 'page',
      icon: 'documentation'
    }
  }, {
    path: '/routeparam',
    component: function component() {
      return import('@/yy/pages/route/adrouteparam.vue');
    },
    name: 'routeparam/home',
    meta: {
      title: '公共参数管理',
      name: 'routeparam',
      icon: 'table'
    }
  }]
}, {
  path: '/tpage',
  redirect: '/tpage',
  component: Layout,
  name: 'tpage',
  meta: {
    title: '页面控制',
    icon: 'size'
  },
  children: [{
    path: '/adtpage',
    component: function component() {
      return import('@/yy/pages/window/adtpage.vue');
    },
    name: 'adtpage/home',
    meta: {
      title: '页面',
      name: 'tpage',
      icon: 'T'
    }
  }, {
    path: '/adtpagepath',
    component: function component() {
      return import('@/yy/pages/window/adtpagepath.vue');
    },
    name: 'adtpagepath/home',
    meta: {
      title: '页面路径',
      name: 'tpagepath',
      icon: 'tree'
    }
  }]
}, {
  path: '/window',
  redirect: '/window',
  component: Layout,
  name: 'window',
  meta: {
    title: '弹窗控制',
    icon: 'danchuang'
  },
  children: [{
    path: '/adwindow',
    component: function component() {
      return import('@/yy/pages/window/adwindow.vue');
    },
    name: 'window/home',
    meta: {
      title: '弹窗',
      name: 'window',
      icon: 'money'
    }
  }, {
    path: '/adwindowlog',
    component: function component() {
      return import('@/yy/pages/window/adwindowlog.vue');
    },
    name: 'windowlog/home',
    meta: {
      title: '弹窗数据',
      name: 'windowlog',
      icon: 'eye-open'
    }
  }]
}, {
  path: '/ad',
  redirect: '/ad',
  component: Layout,
  name: '广告',
  meta: {
    title: '广告管理',
    icon: 'ad'
  },
  children: [{
    path: '/adidconfig',
    component: function component() {
      return import('@/yy/pages/ad/adidconfig.vue');
    },
    name: 'adidconfig/home',
    meta: {
      title: '广告位管理',
      name: 'adid',
      icon: 'tree-table'
    }
  }, {
    path: '/admaparam',
    component: function component() {
      return import('@/yy/pages/ad/admaparam.vue');
    },
    name: 'maparam/home',
    meta: {
      title: '广告参数配置',
      name: 'maparam',
      icon: 'table'
    }
  }, {
    path: '/admanufactor',
    component: function component() {
      return import('@/yy/pages/ad/admanufactor.vue');
    },
    name: 'manufactor/home',
    meta: {
      title: '快应用配置',
      name: 'manufactor',
      icon: 'star'
    }
  }, {
    path: '/manuconfig',
    component: function component() {
      return import('@/yy/pages/ad/admanuconfig.vue');
    },
    name: 'manufactor/config',
    meta: {
      title: '厂商配置',
      name: 'manuconfig',
      icon: 'mobile'
    }
  }]
}, {
  path: '/operation',
  redirect: '/运营',
  component: Layout,
  name: '运营',
  meta: {
    title: '运营管理',
    icon: 'yunying'
  },
  children: [{
    path: '/adchannel',
    component: function component() {
      return import('@/yy/pages/channel/adchannel.vue');
    },
    name: 'channel/home',
    meta: {
      title: '渠道',
      name: 'channel',
      icon: 'tree'
    }
  }, {
    path: '/adblacklist',
    component: function component() {
      return import('@/yy/pages/operation/adblacklist.vue');
    },
    name: 'blacklist/home',
    meta: {
      title: '黑白名单',
      name: 'blacklist',
      icon: 'eye'
    }
  }, {
    path: '/adblackapp',
    component: function component() {
      return import('@/yy/pages/operation/adblackapp.vue');
    },
    name: 'blackapp/home',
    meta: {
      title: 'APP黑名单',
      name: 'blackapp',
      icon: 'eye'
    }
  }, {
    path: '/adcomplain',
    component: function component() {
      return import('@/yy/pages/operation/adcomplain.vue');
    },
    name: 'complain/home',
    meta: {
      title: '投诉选项',
      name: 'complain',
      icon: 'tree-table'
    }
  }, {
    path: '/adcomplainlog',
    component: function component() {
      return import('@/yy/pages/operation/adcomplainlog.vue');
    },
    name: 'complainlog/home',
    meta: {
      title: '投诉记录',
      name: 'complainlog',
      icon: 'eye-open'
    }
  }, {
    path: '/adbpage',
    component: function component() {
      return import('@/yy/pages/window/adbpage.vue');
    },
    name: 'adbpage/home',
    meta: {
      title: '后退落地页',
      name: 'bpage',
      icon: 'B'
    }
  }, {
    path: '/adfeedback',
    component: function component() {
      return import('@/yy/pages/operation/adfeedback.vue');
    },
    name: 'feedback/home',
    meta: {
      title: '用户反馈',
      name: 'feedback',
      icon: 'star'
    }
  }]
}, {
  path: '/profile',
  component: Layout,
  redirect: '/profile/index',
  hidden: true,
  children: [{
    path: 'index',
    component: function component() {
      return import('@/yy/profile/index');
    },
    name: 'Profile',
    meta: {
      title: 'Profile',
      icon: 'user',
      noCache: true
    }
  }]
}];

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export var asyncRoutes = [{
  path: '/finance',
  redirect: '/finance',
  component: Layout,
  name: '财务',
  meta: {
    title: '财务',
    icon: 'money',
    roles: ['admin']
  },
  children: [{
    path: 'ads',
    component: function component() {
      return import('@/yy/pages/finance/finance-ads.vue');
    },
    name: 'finance/ads',
    meta: {
      title: 'ADS - 鲸鸿动能',
      name: 'ads',
      icon: 'ads',
      roles: ['admin']
    }
    // meta: {title: 'ads', name: 'ads', icon: '@/icons/svg/ads.png'},
  }, {
    path: 'agd',
    component: function component() {
      return import('@/yy/pages/finance/finance-agd.vue');
    },
    name: 'finance/agd',
    meta: {
      title: 'AGD Pro应用变现',
      name: 'agd',
      icon: 'agd',
      roles: ['admin']
    }
  }, {
    path: 'invest',
    component: function component() {
      return import('@/yy/pages/finance/finance-invest.vue');
    },
    name: 'finance/invest',
    meta: {
      title: '每日支出',
      name: 'invest',
      icon: 'money',
      roles: ['admin']
    }
    // meta: {title: 'ads', name: 'ads', icon: '@/icons/svg/ads.png'},
  }]
}, {
  path: '/system',
  component: Layout,
  redirect: '/system',
  alwaysShow: true,
  // will always show the root menu
  name: '系统',
  meta: {
    title: '系统',
    roles: ['admin'],
    // you can set roles in root nav
    icon: 'shezhi'
  },
  children: [{
    path: 'dept',
    component: function component() {
      return import('@/yy/pages/sys/dept/index.vue');
    },
    name: 'dept',
    meta: {
      title: '部门管理',
      roles: ['admin'],
      name: 'dept',
      icon: 'local-dept'
    }
  }, {
    path: 'menu',
    component: function component() {
      return import('@/yy/pages/sys/menu/index.vue');
    },
    name: 'menu',
    meta: {
      title: '菜单管理',
      roles: ['admin'],
      name: 'menu',
      icon: 'local-menu'
    }
  }, {
    path: 'role',
    component: function component() {
      return import('@/yy/pages/sys/role/index.vue');
    },
    name: 'role',
    meta: {
      title: '角色管理',
      name: 'role',
      icon: 'local-role'
    }
  }, {
    path: 'user',
    component: function component() {
      return import('@/yy/pages/sys/user/index.vue');
    },
    name: 'user',
    meta: {
      title: '用户管理',
      name: 'user',
      icon: 'user'
    }
  }, {
    path: 'log2',
    component: function component() {
      return import('@/yy/pages/sys/operationLog/index.vue');
    },
    name: 'log2',
    meta: {
      title: '系统记录',
      name: 'log2',
      icon: 'local-notice'
    }
  }]
}, {
  path: '*',
  redirect: '/404',
  hidden: true
}];
var createRouter = function createRouter() {
  return new Router({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;