"use strict";

module.exports = {
  title: 'CURRY30',
  /**
   * @type {boolean} true | false
   * @description Whether show the settings right-panel
   */
  showSettings: true,
  /**
   * @type {boolean} true | false
   * @description Whether need tagsView
   */
  tagsView: true,
  prod_url: 'https://houtai.qding8.com',
  /**
   * 通用服务器设置
   */
  url: process.env.VUE_APP_BASE_API,
  // 正式服务器2
  // url: 'https://houtai.qding8.com', // 正式服务器2
  // url: 'http://127.0.0.1:8889', // 正式服务器2
  /**
   * 通用服务器端口
   */
  port: '8889',
  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: false,
  /**
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: false,
  ws: null,
  initWebsocket: function initWebsocket(token) {
    console.log('token:', token);
    if (token) {
      if (this.ws == null) {
        this.ws = new WebSocket('ws://' + this.url + ':' + this.port + '/api/dashboard?type=cms&token=' + token);
      } else {
        if (this.ws.readyState != 1) {
          // this.ws.close()
          // this.ws = null
          this.ws = new WebSocket('ws://' + this.url + ':' + this.port + '/api/dashboard?type=cms&token=' + token);
        }
      }
    } else {
      console.log('TOKEN NULL');
    }
  },
  /**
   * @type {string | array} 'production' | ['production', 'development']
   * @description Need show err logs component.
   * The default is only used in the production env
   * If you want to also use it in dev, you can pass ['production', 'development']
   */
  errorLog: 'production'
};