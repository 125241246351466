"use strict";

var _interopRequireDefault = require("D:/ad/ad-be-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.symbol.async-iterator");
require("core-js/modules/es6.symbol");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.array.from");
require("core-js/modules/es6.regexp.to-string");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _requestController = require("../../utils/request-controller");
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _elementUi = require("element-ui");
var _vue = _interopRequireDefault(require("vue"));
function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {},
  data: function data() {
    return {
      apps: [],
      defaultSettings: require("../../settings.js"),
      selected_app: {}
    };
  },
  created: function created() {
    var self = this;
    this.selected_app = _jsCookie.default.get('selected_app') ? JSON.parse(_jsCookie.default.get('selected_app')) : {};
    if (!this.selected_app || !this.selected_app.id) {
      _jsCookie.default.set('selected_app', null);
      _vue.default.prototype.$message({
        message: '暂无已选择APP',
        type: 'success',
        duration: 1500
      });
    }
    self.getAppList();
    self.getParamList();
  },
  methods: {
    server_change: function server_change($event) {
      var self = this;
      if ($event == '') {
        self.selected_app = null;
        _jsCookie.default.set('selected_app', null);
        _elementUi.Message.success('删除已选择APP！');
        return;
      }
      var _iterator = _createForOfIteratorHelper(self.apps),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var server = _step.value;
          if (server.id == $event) {
            self.selected_app = server;
            break;
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      _jsCookie.default.set('selected_app', self.selected_app);
      _elementUi.Message.success('当前APP变更为：' + self.selected_app.name + '  (id ' + self.selected_app.id + ')');
    },
    getAppList: function getAppList() {
      var self = this;
      (0, _requestController.postRequest)('ad/app/getapppage', {}, function (res) {
        if (res.body.code != '200') {
          self.$notify({
            title: res.body.msg,
            message: res.body.data,
            type: 'error',
            duration: 2000
          });
        } else {
          self.apps = res.data.data.list;
          _jsCookie.default.set('apps', self.apps);
        }
      });
    },
    getParamList: function getParamList() {
      var self = this;
      var sort = {
        'keyword': 'string',
        'pageIndex': 1,
        'pageSize': 50,
        'pageSorts': [{
          'asc': true
        }]
      };
      (0, _requestController.postRequest)('ad/routeparam/getrouteparampage', sort, function (res) {
        if (res.body.code != '200') {
          self.$notify({
            title: res.body.msg,
            message: res.body.data,
            type: 'error',
            duration: 2000
          });
        } else {
          _jsCookie.default.set('params', res.data.data.list);
        }
      });
    }
  }
};