"use strict";

var _interopRequireDefault = require("D:/ad/ad-be-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.symbol.async-iterator");
require("core-js/modules/es6.symbol");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.array.from");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.to-string");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
exports.mockXHR = mockXHR;
require("core-js/modules/es6.regexp.constructor");
var _toConsumableArray2 = _interopRequireDefault(require("D:/ad/ad-be-cms/node_modules/@babel/runtime/helpers/toConsumableArray.js"));
var _mockjs = _interopRequireDefault(require("mockjs"));
var _utils = require("../src/utils");
var _user = _interopRequireDefault(require("./user"));
var _role = _interopRequireDefault(require("./role"));
var _article = _interopRequireDefault(require("./article"));
var _remoteSearch = _interopRequireDefault(require("./remote-search"));
function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
var mocks = [].concat((0, _toConsumableArray2.default)(_user.default), (0, _toConsumableArray2.default)(_role.default), (0, _toConsumableArray2.default)(_article.default), (0, _toConsumableArray2.default)(_remoteSearch.default));

// for front mock
// please use it cautiously, it will redefine XMLHttpRequest,
// which will cause many of your third-party libraries to be invalidated(like progress event).
function mockXHR() {
  // mock patch
  // https://github.com/nuysoft/Mock/issues/300
  _mockjs.default.XHR.prototype.proxy_send = _mockjs.default.XHR.prototype.send;
  _mockjs.default.XHR.prototype.send = function () {
    if (this.custom.xhr) {
      this.custom.xhr.withCredentials = this.withCredentials || false;
      if (this.responseType) {
        this.custom.xhr.responseType = this.responseType;
      }
    }
    this.proxy_send.apply(this, arguments);
  };
  function XHR2ExpressReqWrap(respond) {
    return function (options) {
      var result = null;
      if (respond instanceof Function) {
        var body = options.body,
          type = options.type,
          url = options.url;
        // https://expressjs.com/en/4x/api.html#req
        result = respond({
          method: type,
          body: JSON.parse(body),
          query: (0, _utils.param2Obj)(url)
        });
      } else {
        result = respond;
      }
      return _mockjs.default.mock(result);
    };
  }
  var _iterator = _createForOfIteratorHelper(mocks),
    _step;
  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var i = _step.value;
      _mockjs.default.mock(new RegExp(i.url), i.type || 'get', XHR2ExpressReqWrap(i.response));
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
}
var _default = exports.default = mocks;