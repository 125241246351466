var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "drawer-container" }, [
    _c(
      "div",
      [
        _c("el-divider", [
          _c("h3", { staticClass: "drawer-title" }, [_vm._v("全局配置")]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "drawer-item" },
          [
            _c("span", { staticStyle: { "font-weight": "bold" } }, [
              _vm._v(
                " 已选：" +
                  _vm._s(
                    _vm.selected_app
                      ? _vm.selected_app.name +
                          "  (id " +
                          _vm.selected_app.id +
                          ")"
                      : "暂无"
                  )
              ),
            ]),
            _vm._v(" "),
            _c(
              "el-select",
              {
                staticStyle: { "padding-top": "6px" },
                attrs: {
                  clearable: "",
                  placeholder: "app",
                  value: _vm.selected_app ? _vm.selected_app.name : "请选择",
                  "popper-class": "theme-picker-dropdown998",
                },
                on: {
                  change: function ($event) {
                    return _vm.server_change($event)
                  },
                },
              },
              _vm._l(_vm.apps, function (item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: {
                    label: item.name + "  (id:" + item.id + ")",
                    value: item.id,
                  },
                })
              }),
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "drawer-item" },
          [
            _c("span", { staticStyle: { "font-weight": "bold" } }, [
              _vm._v(" Contact Me "),
            ]),
            _vm._v(" "),
            _c("el-input", {
              staticStyle: { "padding-top": "6px" },
              attrs: { value: "cina@outlook.com" },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }