"use strict";

var _interopRequireDefault = require("D:/ad/ad-be-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.sort");
var _mockjs = _interopRequireDefault(require("mockjs"));
var List = [];
var count = 100;
var baseContent = '<p>I am testing data, I am testing data.</p><p><img src="https://wpimg.wallstcn.com/4c69009c-0fd4-4153-b112-6cb53d1cf943"></p>';
var image_uri = 'https://wpimg.wallstcn.com/e4558086-631c-425c-9430-56ffb46e70b3';
for (var i = 0; i < count; i++) {
  List.push(_mockjs.default.mock({
    id: '@increment',
    timestamp: +_mockjs.default.Random.date('T'),
    author: '@first',
    reviewer: '@first',
    title: '@title(5, 10)',
    content_short: 'mock data',
    content: baseContent,
    forecast: '@float(0, 100, 2, 2)',
    importance: '@integer(1, 3)',
    'type|1': ['CN', 'US', 'JP', 'EU'],
    'status|1': ['published', 'draft'],
    display_time: '@datetime',
    comment_disabled: true,
    pageviews: '@integer(300, 5000)',
    image_uri: image_uri,
    platforms: ['a-platform']
  }));
}
var _default = exports.default = [{
  url: '/vue-element-admin/article/list',
  type: 'get',
  response: function response(config) {
    var _config$query = config.query,
      importance = _config$query.importance,
      type = _config$query.type,
      title = _config$query.title,
      _config$query$page = _config$query.page,
      page = _config$query$page === void 0 ? 1 : _config$query$page,
      _config$query$limit = _config$query.limit,
      limit = _config$query$limit === void 0 ? 20 : _config$query$limit,
      sort = _config$query.sort;
    var mockList = List.filter(function (item) {
      if (importance && item.importance !== +importance) return false;
      if (type && item.type !== type) return false;
      if (title && item.title.indexOf(title) < 0) return false;
      return true;
    });
    if (sort === '-id') {
      mockList = mockList.reverse();
    }
    var pageList = mockList.filter(function (item, index) {
      return index < limit * page && index >= limit * (page - 1);
    });
    return {
      code: 20000,
      data: {
        total: mockList.length,
        items: pageList
      }
    };
  }
}, {
  url: '/vue-element-admin/article/detail',
  type: 'get',
  response: function response(config) {
    var id = config.query.id;
    for (var _i = 0, _List = List; _i < _List.length; _i++) {
      var article = _List[_i];
      if (article.id === +id) {
        return {
          code: 20000,
          data: article
        };
      }
    }
  }
}, {
  url: '/vue-element-admin/article/pv',
  type: 'get',
  response: function response(_) {
    return {
      code: 20000,
      data: {
        pvData: [{
          key: 'PC',
          pv: 1024
        }, {
          key: 'mobile',
          pv: 1024
        }, {
          key: 'ios',
          pv: 1024
        }, {
          key: 'android',
          pv: 1024
        }]
      }
    };
  }
}, {
  url: '/vue-element-admin/article/create',
  type: 'post',
  response: function response(_) {
    return {
      code: 20000,
      data: 'success'
    };
  }
}, {
  url: '/vue-element-admin/article/update',
  type: 'post',
  response: function response(_) {
    return {
      code: 20000,
      data: 'success'
    };
  }
}];